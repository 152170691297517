import React, {useEffect, useState} from "react";
import {Parallax} from 'react-parallax';
import Picture from '../../../images/ForParallax.jpg'
import {Grid, makeStyles, Typography} from "@material-ui/core";
import CountUp from 'react-countup';
import {Done, Grade, InsertEmoticon, ThumbUp} from "@material-ui/icons";
import Fade from '@material-ui/core/Zoom';
import {usePath} from "hookrouter";


function Parallax1() {
    const useStyles = makeStyles(theme => ({
        image: {
            opacity: 0.5
        },
        container: {
            position: "relative",
            textAlign: "center",
            color: "white",
        },
        centered: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            lineHeight: 20
        },

    }));


    const classes = useStyles();
    const path = usePath()


    const [counterItem, setCounterItem] = useState(false)

    useEffect(() => {
        if (path === "/") {
            window.addEventListener('scroll', handleScroll);
        }

        return () => window.removeEventListener('scroll', handleScroll);
    }, [path])

    function handleScroll(event) {
        if (path === "/") {
            const element = document.getElementById("LoadOnScroll")
            console.log(element.getBoundingClientRect().top)
            if (element.getBoundingClientRect().top < 450) {
                setCounterItem(true)
            }
        }

    }

    return (<div style={{height: "50vh"}}>
            <Fade in={counterItem} timeout={{enter: 500}} direction={"right"}><Grid className={classes.container}
                                                                                     style={{minHeight: 10}}
                                                                                     id={"LoadOnScroll"}>
                {!counterItem ? null : <Parallax
                    bgImage={Picture}
                    bgImageAlt="the cat"
                    strength={500}
                >


                    <Grid style={{height: "50vh"}}>
                        <Grid container justify={"center"} className={classes.centered}
                              style={{
                                  margin: "auto",
                                  background: "rgba(79,27,29,0.71)",
                                  height: "100%",
                                  width: "100%"
                              }}>

                            <Grid container justify={"center"} style={{padding: 5}}>
                                <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Typography style={{textAlign: "center", padding: 20, fontWeight: "bold"}}
                                                variant={"h5"}>
                                        SOME FACTS ABOUT US
                                    </Typography>
                                </Grid>
                                <Grid xl={3} lg={3} md={6} sm={6} xs={6}>
                                    <div>
                                        <Typography>
                                            <InsertEmoticon fontSize={"large"} style={{color: "#ffd03d"}}/>
                                        </Typography>
                                        <Typography variant={"h4"} style={{color: "#ffd03d"}}>
                                            <CountUp start={0} end={1200}></CountUp>+
                                        </Typography>
                                        <Typography style={{fontWeight: "bold"}}>
                                            HAPPY CLIENTS
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid xl={3} lg={3} md={6} sm={6} xs={6}>
                                    <div>
                                        <Typography>
                                            <ThumbUp fontSize={"large"} style={{color: "#ffd03d"}}/>
                                        </Typography>
                                        <Typography variant={"h4"} style={{color: "#ffd03d"}}>
                                            <CountUp start={0} end={1800}></CountUp>+
                                        </Typography>
                                        <Typography style={{fontWeight: "bold"}}>
                                            CLIENT REVIEWS
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid xl={3} lg={3} md={6} sm={6} xs={6}>
                                    <div>
                                        <Typography>
                                            <Done fontSize={"large"} style={{color: "#ffd03d"}}/>
                                        </Typography>
                                        <Typography variant={"h4"} style={{color: "#ffd03d"}}>
                                            <CountUp start={0} end={2500}></CountUp>+
                                        </Typography>
                                        <Typography style={{fontWeight: "bold"}}>
                                            PROJECTS DONE
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid xl={3} lg={3} md={6} sm={6} xs={6}>
                                    <div>
                                        <Typography>
                                            <Grade fontSize={"large"} style={{color: "#ffd03d"}}/>
                                        </Typography>
                                        <Typography variant={"h4"} style={{color: "#ffd03d"}}>
                                            <CountUp start={0} end={5}></CountUp>
                                        </Typography>
                                        <Typography style={{fontWeight: "bold"}}>
                                            STAR RATING
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Parallax>}
            </Grid></Fade></div>
    );
}

export default Parallax1