import React from "react";
import "./footer-style.css";
import {Grid, Typography} from "@material-ui/core";
import {A} from "hookrouter"
import Logo from "../../../images/Logo1.png"
import {usePath} from "hookrouter"
import {navigate} from "hookrouter";


function Footer() {
    const path = usePath()

    function GoHashLink(url) {
        if (path !== "/") {
            navigate("/")
        }
        setTimeout(function () {
            let goHash = document.getElementById("GoHashLink")
            goHash.setAttribute("href", url);
            goHash.click()
        }, path==="/"?0:2000);
    }

    return (<footer className="footer-section">
            <div className="container">
                <div className="footer-cta pt-5 pb-5">
                    <Grid container justify={"center"}>
                        {/*<Grid xl={3} lg={3} md={3} sm={12} xs={12} style={{marginBottom: 30}}>*/}
                        {/*    <div className="single-cta">*/}
                        {/*        <div className="cta-text">*/}
                        {/*            <h4>Skype</h4>*/}
                        {/*            <span>gradeawriters_1</span>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                        <Grid xl={4} lg={4} md={6} sm={12} xs={12} style={{marginBottom: 30}}>
                            <div className="single-cta">
                                <div className="cta-text">
                                    <h4>Call us</h4>
                                    <span>+971562626178</span>
                                </div>
                            </div>
                        </Grid>
                        <Grid xl={4} lg={4} md={4} sm={12} xs={12} style={{marginBottom: 30}}>
                            <div className="single-cta">
                                <div className="cta-text">
                                    <h4>Mail us</h4>
                                    <span>alifwriters@gmail.com</span>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="footer-content pt-5 pb-5">
                    <Grid container justify={"center"}>
                        <Grid xl={4} md={4} style={{marginBottom: 50}}>
                            <div className="footer-widget">
                                <div className="footer-logo">
                                    <A href="/"><img src={Logo}
                                                              className="img-fluid" alt="logo"/></A>
                                </div>
                                <div className="footer-text" style={{padding: 10}}>
                                    <p>Alif Writers is an independent service that offers clients with reference and
                                        sample work for
                                        their orders. Our mission is to provide our customers with high quality services
                                        in a timely manner.</p>
                                </div>
                                <div style={{padding: 10}} className="footer-social-icon">
                                    <span>Follow us</span>
                                    {/*<a href="#"><LinkedIn/></a>*/}
                                    {/*<a href="https://www.facebook.com/GradeAWritersOfficial"><FacebookIcon/></a>*/}
                                    {/*<a href="#"><Instagram/></a>*/}
                                    {/*<a style={{fontSize: 25, textDecoration: "none"}} target={"_blank"}*/}
                                    {/*   href="live:gradeawriters_1"*/}
                                    {/*   class="fa fa-skype"></a>*/}
                                    <a style={{fontSize: 25, textDecoration: "none"}} target={"_blank"}
                                       href="https://www.facebook.com/Alif-Writers-107415591431495/"
                                       class="fa fa-facebook"></a>
                                    <a style={{fontSize: 25, textDecoration: "none"}} target={"_blank"} href="https://www.instagram.com/alifwriters/"
                                       class="fa fa-instagram"></a>
                                </div>
                            </div>
                        </Grid>
                        <Grid xl={4} lg={4} md={6} xs={12} style={{padding: 10, marginBottom: 30}}>
                            <div className="footer-widget">
                                <div className="footer-widget-heading">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul>
                                    <li><A style={{textDecoration: "none", cursor: "pointer"}} href="/">Home</A></li>
                                    <li>
                                        <a style={{textDecoration: "none", cursor: "pointer"}} onClick={() => GoHashLink("#aboutus")}>About Us</a>
                                    </li>
                                    <li><a style={{textDecoration: "none", cursor: "pointer"}} onClick={() => GoHashLink("#services")}>Services</a></li>
                                    <li><a style={{textDecoration: "none", cursor: "pointer"}} target={"_blank"}
                                        href="https://api.whatsapp.com/send?phone=971562626178&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202.">Contact
                                        Us</a></li>
                                </ul>
                            </div>

                        </Grid>
                        {/*<Grid xl={4} lg={4} md={6} style={{marginBottom: 50}}>*/}
                        {/*    <div className="footer-widget">*/}
                        {/*        <div className="footer-widget-heading">*/}
                        {/*            <h3>Subscribe</h3>*/}
                        {/*        </div>*/}
                        {/*        <div className="footer-text mb-25">*/}
                        {/*            <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>*/}
                        {/*        </div>*/}
                        {/*        <div className="subscribe-form">*/}
                        {/*            <button><i className="fab fa-telegram-plane"></i></button>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</Grid>*/}
                    </Grid>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <Grid container justify={"center"}>
                        <Grid xl={4} lg={4} md={4} style={{}}
                              className="col-xl-6 col-lg-6 text-center text-lg-left">
                            <div className="copyright-text">
                                <p>Copyright &copy; 2021, All Right Reserved <A style={{textDecoration: "none", cursor: "pointer"}} href="/">Alif Writers</A></p>
                            </div>
                        </Grid>
                        <Grid xl={4} lg={4} md={4} style={{marginBottom: 30}}
                              className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                            <div className="footer-menu">
                                <ul>
                                    <li><A style={{textDecoration: "none", cursor: "pointer"}} href="/">Home</A></li>
                                    <li>
                                        <A style={{textDecoration: "none", cursor: "pointer"}} href="/TermAndConditions">Terms</A>
                                    </li>
                                    <li><A style={{textDecoration: "none", cursor: "pointer"}} target={"_blank"}
                                           href="https://api.whatsapp.com/send?phone=923055181214&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202.">Contact</A>
                                    </li>
                                </ul>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </footer>
    );
}

export default Footer