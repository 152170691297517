import React from "react";
import View1 from "../../../images/View.jpg"
import "./view.css"


function MainView(){
    return(
        <div style={{backgroundColor: "#4f1b1d"}} id={"#home"} >
            <img src={View1} className="imageHeight" width={"100%"}/>
        </div>
    );
}

export default MainView