import React, {Fragment} from "react";
import Image1 from "../../images/SampleWork.jpg"
import SampleImage1 from "../../images/SampleImage1.png"
import "./view.css"
import {Grid, Paper, Typography} from "@material-ui/core";

function Samples() {

    return (
        <Fragment>
            <div>
                <img src={Image1} className="imageHeight" width={"100%"}/>
            </div>
            <div style={{padding: 30}}>
                <Grid container justify={"center"}>
                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                        <Paper elevation={3} style={{padding: 10}}>
                            <Paper style={{padding: 30, backgroundColor: "white"}}>
                                <div style={{marginTop: 50}}></div>
                                <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Typography variant={"h5"} align={"center"}>
                                        EARLY YEARS LEARNING BY CHILD-CENTERED AND PERSONALIZED APPROACH
                                    </Typography>
                                    <div className="spaceTop"></div>
                                    <Typography align={"center"}>
                                        By Name
                                    </Typography>
                                    <div className="spaceTop200"></div>
                                    <Typography align={"center"}>The Name of the Class Professor</Typography>
                                    <div className="spaceTop"></div>
                                    <Typography align={"center"}>The Name of the School</Typography>
                                    <div className="spaceTop"></div>
                                    <Typography align={"center"}>The City and State where it is located</Typography>
                                    <div className="spaceTop"></div>
                                    <Typography align={"center"}>Date</Typography>

                                </Grid>
                                <div className="spaceTop"></div>
                                <div className="spaceTop"></div>
                                <Typography style={{fontWeight: "bold"}} variant={"h6"}>Introduction</Typography>
                                <div className="spaceBottom"></div>
                                <Typography>The early year education or Early Years Establishment Stage is the important
                                    step in
                                    student’s
                                    life. It started from the two to three years to the six years. The proper
                                    professionals are
                                    needed at this stage to develop the student’s basic skills to maintain their power
                                    of
                                    decision
                                    making.</Typography>
                                <Typography>The specific learning areas include:</Typography>
                                <div className="spaceBottom"></div>
                                <Typography>• Creative development;</Typography>
                                <Typography>• Literacy and language;</Typography>
                                <Typography>• Mathematics;</Typography>
                                <Typography>• Social and personal development;</Typography>
                                <Typography>• And understanding of the world.</Typography>
                                <div className="spaceTop"></div>
                                <div>
                                    <img src={SampleImage1} className="imageHeight" width={"100%"}/>
                                </div>
                            </Paper>
                        </Paper>
                    </Grid>
                </Grid>
                <div className="spaceTop"></div>
                <Grid container justify={"center"}>
                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
                        <Paper elevation={3} style={{padding: 10}}>
                            <Paper style={{padding: 30, backgroundColor: "white"}}>
                                <div style={{marginTop: 50}}></div>
                                <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <Typography style={{fontWeight: "bold"}} variant={"h6"}>
                                        ASSIGNMENTS 3 AND 4
                                    </Typography>
                                    <div className="spaceTop"></div>
                                    <Typography style={{fontWeight: "bold"}}>GROUP ESSAY BBUS
                                        305</Typography>
                                    <div className="spaceTop"></div>
                                    <Typography style={{fontWeight: "bold"}}>Intellectual property Law:
                                        CLO4</Typography>
                                    <div className="spaceTop"></div>
                                    <Typography style={{fontWeight: "bold"}}>Individual or groups from
                                        2,3,4 or 5 persons per group</Typography>
                                    <div className="spaceTop"></div>
                                    <Typography style={{fontWeight: "bold"}} variant={"h6"}>Task</Typography>
                                    <div className="spaceTop">
                                    </div>
                                    <Typography style={{fontWeight: "bold"}}>ASSIGNMENT 3 (10
                                        marks)</Typography>
                                    <div className="spaceTop"></div>
                                    <Typography style={{fontWeight: "bold"}}>Describe the UAE law with
                                        regard to TRADEMARKS as follows:</Typography>
                                    <div className="spaceTop"></div>
                                    <Typography>1) Definitions</Typography>
                                    <Typography>2) Registration of Trademarks and their striking off
                                        (deletion)</Typography>
                                    <div className="spaceTop"></div>
                                    <Typography style={{fontWeight: "bold"}}>Assignment 4 (10 marks)</Typography>
                                    <div className="spaceTop"></div>
                                    <Typography style={{fontWeight: "bold"}}>Describe the UAE law with regard to
                                        TRADEMARKS as follows:</Typography>
                                    <Typography>3) Transfer of Ownership and Mortgage of Trademarks</Typography>
                                    <Typography>4) Contracts licensing the use of Trademarks</Typography>
                                    <Typography>5) Marks allocated for the control or inspection of specific products</Typography>
                                    <Typography>6) Sanctions</Typography>
                                    <Typography>7) General and Transitory Provisions</Typography>

                                    <div className="spaceTop"></div>
                                    <Typography style={{color: "red", fontWeight: "bold"}}>Instructions: Read the pdf document in moodle titled “Trademark Law StatuteUAE”, then paraphrase or write in your own words.</Typography>
                                    <div className="spaceTop"></div>
                                    <Typography style={{color: "red"}}>SUBMIT AS ONE WORD DOCUMENTBUT CLEARLY PUT THE TITLE ASSIGNMENT 3 ANDASSIGNMENT 4</Typography>

                                    <div className="spaceTop"></div>
                                    <Typography style={{backgroundColor: "#ffd03d"}}>PLEASE STATE CLEARLY ON COVER OF DOCUMENT THE ID NUMBERS AND NAMES OF ALL MEMBERS.</Typography>
                                    <div className="spaceTop"></div>
                                    <Typography style={{backgroundColor: "#ffd03d"}}>FAILURE TO INCLUDE THE NAMES OF THE GROUP MEMBERS WILL RESULT IN THOSE PEOPLE NOT.</Typography>

                                </Grid>
                                <div className="spaceTop"></div>
                                <div>
                                    <img src={SampleImage1} className="imageHeight" width={"100%"}/>
                                </div>
                            </Paper>
                        </Paper>
                    </Grid>
                </Grid>
            </div>

        </Fragment>
    );
}

export default Samples