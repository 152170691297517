import React from "react";
import {Grid, Paper, Typography} from "@material-ui/core";

function Disclaimer() {
    return (<Grid container justify={"center"}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <div style={{padding: 50}}>
                    <div style={{padding: 0}}>
                        <Typography
                            style={{color: "#4f1b1d", textAlign: "center", padding: 0, fontWeight: "bold"}}
                        >
                            DISCLAIMER
                        </Typography>
                        {/*<Typography style={{textAlign: "center", padding: 0, fontWeight: "bold", marginBottom: 10}}*/}
                        {/*            variant={"h5"}>*/}
                        {/*    YOUR PAPERWORK, OUR RESPONSIBILITY*/}
                        {/*</Typography>*/}
                    </div>
                    <Typography style={{textAlign: "center"}}>
                        Alif Writers is an editing and proofreading service that assists clients by offering reference
                        and sample materials as well as by proofreading and editing their work to ensure that is it
                        meets their guidelines. The products offered through this website are intended for study and
                        consultancy purposes only.
                    </Typography>
                </div>
            </Grid>

        </Grid>
    );
}

export default Disclaimer