import React, {Fragment} from "react";
import Image1 from "../../images/t&c.jpg"
import "./view.css"
import {Paper, Typography} from "@material-ui/core";

function Term_Condition() {

    return (
        <Fragment>
            <div>
                <img src={Image1} className="imageHeight" width={"100%"}/>
            </div>
            <div style={{padding: 30}}>
                <Paper elevation={3} style={{padding: 20}}>
                    <Typography>
                        These Terms and Conditions constitute a binding agreement between GradeAWriters.com, the
                        Company,
                        and any individual who becomes a Customer of the Company. When any individual becomes a Customer
                        of
                        the Company, they agree to be automatically bound by this Terms and Conditions Agreement.
                    </Typography>
                    <div className="spaceTop"></div>
                    <Typography variant={"h6"}>1. Clarification of the terms</Typography>
                    <div className="spaceBottom"></div>

                    <Typography>1.1 Agreement alludes to Terms and Conditions.</Typography>
                    <Typography>1.2 Terms-the Company-refer to GradeAWriters.com, the entity that offers editing
                        services to
                        Customers.</Typography>
                    <Typography>1.3 Terms-the Customer-The Customer is a person or persons who hands in Orders to the
                        Company for
                        deliberation, and adheres to the Terms and Conditions in this Agreement.</Typography>
                    <Typography>1.4 Messaging System is the software that facilitates exchange of information between
                        the Customer
                        and the Writer, or with a member of the Support Team.</Typography>
                    <Typography> 1.5 Order refers to the request for a Product by the Consumer to the Company which
                        includes specific
                        expectations and a predefined set of sources to be used.</Typography>
                    <Typography>1.6 Product is the outcome of the processed Order which is delivered to the Customer as
                        a digital
                        document as per their requirements.</Typography>
                    <Typography>1.7 Product Revision is a request sent by the Customer to the Company for making amends
                        to the
                        Product in line with the requirements filled in the Order Form.</Typography>
                    <Typography>1.8 Support Staff is the Company’s structural unit tasked with coordinating and
                        facilitating the
                        Order process.</Typography>
                    <Typography>1.9 The Writer is a Company employee who works as a freelancer and engages in the
                        provision of
                        services to the Customer as outlined in the Company Agreement.</Typography>
                    <div className="spaceTop"></div>

                    <Typography variant={"h6"}>2. Order Payment</Typography>
                    <div className="spaceBottom"></div>

                    <Typography>2.1 The payment for the Product is calculated according to the Company’s pricing and
                        should be paid
                        in advance. The Company starts to process the Order only after the payment for the Product is
                        made
                        and is authorized.</Typography>
                    <Typography>2.2 Order can be paid via Credit Card and PayPal.</Typography>
                    <Typography>2.3 The Company’s platform for all payments is PayPal, which requires the Customer to go
                        through
                        PayPal check-out in Order to pay for the Order; To pay for the Order using Credit Card, the
                        Customer
                        is required to fill in the PayPal form. Such actions will not create a permanent PayPal account,
                        but
                        are required to proceed with a one-time transaction.</Typography>
                    <Typography>2.4 The Company is not responsible for any technical or procedural issues that may occur
                        while the
                        payment is processed by PayPal. If the transaction fails, the Customer should check the balance
                        on
                        his/her card, online purchase restrictions and card validity before contacting the Support Staff
                        for
                        assistance.</Typography>
                    <div className="spaceTop"></div>

                    <Typography variant={"h6"}>3. Order Process</Typography>
                    <div className="spaceBottom"></div>

                    <Typography>3.1 Order validation. The Company is entitled to re-checks of details of the Order after
                        the payment
                        to confirm whether instructions provided while filling out the Order Form correspond to those
                        provided manually by the Customer through any communication channel. Members of the Support
                        Staff
                        will contact the Customer to clarify and iron out details in the event of a mismatch. The
                        Support
                        Staff holds the authority to refuse an order based on their judgment.</Typography>
                    <Typography>3.2 Order Volume. Order volume is measured in words only as per the Company’s standards.
                        1 page,
                        Double-spaced equals to 275 words and 1 page Single-spaced is 550 words.</Typography>
                    <Typography>3.3 Change of Order details. Instructions on changes to the range of work by the
                        Customer may be
                        provided by contacting the Support Staff. Modifications that revolve around urgency or
                        complexity of
                        the Order may call for additional payment, which will be done after consultation with the
                        Customer.</Typography>
                    <Typography>3.4 Resources. The Customer is entirely responsible for provision of resources to be
                        used in
                        execution of the Order. In case a Writer requires a source, it is the Customer’s obligation to
                        provide it. Customers should upload all sources before the Writer is assigned in order to
                        guarantee
                        timely delivery. A delay in provision of a source may result in a delay in completion of the
                        work.</Typography>
                    <Typography>3.5 Communication. Communication between the Writer and the Customer is facilitated by
                        the Messaging
                        System which is available on the Customer’s personal profile on the Website, located on the top
                        right of the Specific Order Page. The Messaging System resembles E-mail Communication in all
                        aspects, only that it is more secure for use by Customers. Contact between the Customer and the
                        Support staff can be done 24/7 via E-mail or phone.</Typography>
                    <Typography> 3.6 Progress tracking. Progress can be monitored by the Customer on their personal
                        account’s Order
                        Page. The Communication channels provided can also be used to inquire about the progress of the
                        Order.</Typography>
                    <Typography>
                        3.7 Order delivery. Delivery of the Product within the given deadline is entirely the Company’s
                        responsibility. Support Staff will contact the Customer in the event that more time is needed
                        due to
                        complexities of the task. The Customer can access the Product by downloading it from the
                        Customer’s
                        personal account on the Website. Timely download of the Product is entirely the Customer’s
                        responsibility. Technical issues arising from the Customer’s end which may have prevented timely
                        download of the Product are not the Company’s responsibility.</Typography>
                    <div className="spaceTop"></div>

                    <Typography variant={"h6"}> 4. Order Revision</Typography>
                    <div className="spaceBottom"></div>
                    <Typography>4.1 Free adjustments to the Customer’s Order are valid within a 14-day window after the
                        Order
                        delivery. In the event the Revision is required at a future date, the difficulty of the Revision
                        will influence the extent of additional charges to be applied.</Typography>
                    <Typography>4.2 In the personal account, the Customer can submit a Revision Request.</Typography>
                    <Typography> 4.3 A free Revision can be provided in the event that initial instructions and the
                        Revision
                        instructions match. The Company reserves the right to ask for more payment in order to execute
                        the
                        Revision, if the Revision instructions violate initial Order instructions.</Typography>
                    <Typography>4.4 The deadline for Revision cannot be earlier than the original deadline in the Order
                        form.</Typography>
                    <div className="spaceTop"></div>

                    <Typography variant={"h6"}>5. The Use of Product</Typography>
                    <div className="spaceBottom"></div>

                    <Typography> 5.1 The Products of GradeAWriters.com are not for submission in the form of the
                        Customer’s own
                        research. The main use of the Product is to act as a sample or reference for their own
                        work.</Typography>
                    <Typography> 5.2 Copyright laws are highly upheld by the Company and violation of the Company’s
                        Terms and
                        Conditions is entirely the Customer’s responsibility.</Typography>
                    <div className="spaceTop"></div>

                    <Typography variant={"h6"}>6. Miscellaneous Provisions</Typography>
                    <div className="spaceBottom"></div>

                    <Typography>6.1 All Terms and Conditions that define the rights and obligations of both parties
                        involved in the
                        Contract – the Customer and the Company, are outlined in this Agreement. This document solely,
                        its
                        statements, promises or inducements, are to be viewed as valid or binding to both parties. After
                        accepting the Terms and Conditions, this Agreement replaces all previous verbal or written
                        communications and/or Agreements between the parties.</Typography>
                    <Typography>6.2 The Customer accepts that if a particular set of Terms and Conditions conflicts any
                        State Law,
                        it will not affect the rest of the document. The Customer’s rights and obligations will continue
                        to
                        prevail due to lack of an invalid part in the Agreement.</Typography>
                    <Typography> 6.3 The Agreement is controlled, in its clarification and performance, by the Laws of
                        the State
                        where the Company is located and holds its primary business, primarily in X region.</Typography>
                    <div className="spaceTop"></div>

                    <Typography variant={"h6"}>7. Limitation of Liability</Typography>
                    <div className="spaceBottom"></div>

                    <Typography>The Customer’s decision to accept the above Terms and Conditions of this Agreement does
                        not hold the
                        Company and its employees, shareholders, officers, agents, representatives, directors,
                        affiliates,
                        promotions, subsidiaries, advertising and fulfillment agencies, any third-party providers or
                        sources
                        of information or data and legal advisers (the “Company’s Affiliates”) responsible for any and
                        all
                        losses, rights, damages, claims, and actions of any kind, arising from or related to the
                        Products,
                        including but not limited to:</Typography>
                    <Typography>(a) telephone, electronic, hardware or software, Internet, network, email, or computer
                        malfunctions,
                        failures or difficulties of any kind;</Typography>
                    <Typography>(b) failed, garbled, incomplete or delayed computer transmissions;</Typography>
                    <Typography> (c) any condition brought up by events beyond the control of the Company, that might
                        cause the
                        Product to be corrupted, delayed or disrupted;</Typography>
                    <Typography> (d) any damages or losses of any sort arising in connection with, or as a result of,
                        utilizing the
                        services;</Typography>
                    <Typography>(e) any printing or typographical errors in any materials associated with the
                        service.</Typography>
                </Paper>
            </div>

        </Fragment>
    );
}

export default Term_Condition