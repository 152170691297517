import React, {Fragment, useEffect} from 'react';
import Carousel from 'nuka-carousel';
import {Grid, makeStyles, Typography,} from "@material-ui/core";
import Gif from "../../../images/Review.gif"

function Reviews() {
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        if (
            Math.ceil(window.innerHeight + document.documentElement.scrollTop) !== document.documentElement.offsetHeight
        )
            return
    };

    const useStyles = makeStyles(theme => ({
            nxt_prev:{
                prevButtonText: "Hello",
                nextButtonText: "Hello 2",
            },
        }))
    ;
    const classes = useStyles();
    return (<Fragment >
            <Grid style={{padding: 20}} container justify={"center"}>
                <Grid xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Grid container justify={"center"}>
                        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                            <div style={{padding: 0}}>
                                <Typography
                                    style={{color: "#4f1b1d", textAlign: "center", padding: 0, fontWeight: "bold"}}
                                >
                                    TESTIMONIALS
                                </Typography>
                                <Typography style={{textAlign: "center", padding: 0, fontWeight: "bold"}}
                                            variant={"h5"}>
                                    WHAT OUR CLIENTS SAY
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Carousel>
                        <Grid container justify={"center"}>
                            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div style={{padding: 50}}>
                                    <Typography style={{textAlign: "center"}}>
                                        Alif Writers worked on my dissertation because I did not have enough time to
                                        edit and proofread it. They helped structure and rework it to meet my
                                        university’s requirements. Thank you so much!
                                    </Typography>
                                    <Typography style={{textAlign: "center", padding: 10, fontWeight: "bold"}}
                                                variant={"h6"}>
                                        Saad
                                    </Typography>
                                    <Typography style={{textAlign: "center", marginTop: "-10px"}}>
                                        Dubai
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justify={"center"}>
                            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div style={{padding: 50}}>
                                    <Typography style={{textAlign: "center"}}>
                                        Absolutely amazing service! Got my order hours before the deadline and got good
                                        marks.
                                    </Typography>
                                    <Typography style={{textAlign: "center", padding: 10, fontWeight: "bold"}}
                                                variant={"h6"}>
                                        Fatuma
                                    </Typography>
                                    <Typography style={{textAlign: "center", marginTop: "-10px"}}>
                                        Sharjah
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container justify={"center"}>
                            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                                <div style={{padding: 50}}>
                                    <Typography style={{textAlign: "center"}}>
                                        The sister working for the company helped me a lot. Thank you so much!
                                    </Typography>
                                    <Typography style={{textAlign: "center", padding: 10, fontWeight: "bold"}}
                                                variant={"h6"}>
                                        Zainab
                                    </Typography>
                                    <Typography style={{textAlign: "center", marginTop: "-10px"}}>
                                        Ajman
                                    </Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Carousel>
                </Grid>

                <Grid xl={6} lg={6} md={6} sm={12} xs={12} style={{textAlign: "center"}}>
                    <img style={{borderRadius: "10%", width: "100%"}} src={Gif}/>
                </Grid>

            </Grid>


        </Fragment>
    );
}


export default Reviews;