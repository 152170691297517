import React from "react";
import {Grid, Paper, Typography} from "@material-ui/core";

function AboutUs() {
    return (<Grid id={"aboutus"} container justify={"center"}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <div style={{padding: 50}}>
                    <div style={{padding: 0}}>
                        <Typography
                            style={{color: "#4f1b1d", textAlign: "center", padding: 0, fontWeight: "bold"}}
                        >
                            ABOUT US
                        </Typography>
                        <Typography style={{textAlign: "center", padding: 0, fontWeight: "bold", marginBottom: 10}}
                                    variant={"h5"}>
                            WHO WE ARE
                        </Typography>
                    </div>
                    <Typography style={{textAlign: "center"}}>
                        Alif Writers is an independent service that offers clients with reference and sample work for
                        their orders. Our mission is to provide our customers with high quality services in a timely
                        manner. Established since 2018, our business is comprised of expert with several decades’ worth
                        of experience focused on meeting your exact needs.
                    </Typography>
                </div>
            </Grid>

        </Grid>
    );
}

export default AboutUs