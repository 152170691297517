import React, {useEffect} from 'react';
import './App.css';
import Layout from "./components/layout";
import MainPages from "./components/MainPages";
import {BrowserRouter} from "react-router-dom";
import Routes from "./routes"
import CssBaseline from '@material-ui/core/CssBaseline';
import {usePath} from "hookrouter"

function App() {
    const path = usePath()
    useEffect(() => {
        console.log(path)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        })
    }, [path])
    return (
        <React.Fragment>
            <BrowserRouter>
                <CssBaseline/>
                <Routes/>
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
