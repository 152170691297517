import React, {Fragment} from "react";
import "./nav-style.css"
import "./nav"
import Logo from "../../../images/Logo1.png"
import {A, navigate, usePath} from "hookrouter"

function Header() {
    const path = usePath()

    function GoHashLink(url) {
        if (path !== "/") {
            navigate("/")
        }
        setTimeout(function () {
            let goHash = document.getElementById("GoHashLink")
            goHash.setAttribute("href", url);
            goHash.click()
        }, path === "/" ? 0 : 2000);
    }

    return <Fragment>

        <nav className="navbar navbar-default navbar-expand-lg nav-menu">

            <div className="container-fluid">

                <A href="/" className="navbar-brand text-uppercase"> <img width={"auto"} height={70}
                                                                          src={Logo}/> </A>

                <button className="navbar-toggler nav-button" type="button" data-toggle="collapse"
                        data-target="#myNavbar">
                    <div className="line1 ham"></div>
                    <div className="line2 ham"></div>
                    <div className="line3 ham"></div>
                </button>

                <div className="collapse navbar-collapse justify-content-end" id="myNavbar">

                    <ul className="navbar-nav ml-auto">

                        <li className="nav-item">
                            <A className="nav-link m-2 menu-item" href="/"> Home </A>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link m-2 menu-item " style={{
                                color: "white", cursor: "pointer"
                            }} onClick={() => GoHashLink("#aboutus")}> About Us </a>
                        </li>

                        <li className="nav-item">
                            <A className="nav-link m-2 menu-item" href="/Samples"> Samples </A>
                        </li>

                        <li className="nav-item">
                            <a target={"_blank"} className="nav-link m-2 menu-item"
                               href="https://api.whatsapp.com/send?phone=971562626178&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202."> contact </a>
                        </li>
                    </ul>

                </div>

            </div>


        </nav>

    </Fragment>

}

export default Header

















