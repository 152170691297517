import React, {Fragment} from 'react';
import Header from "./header";
import Footer from "./footer";
import {makeStyles} from "@material-ui/core";
import {WhatsApp} from "@material-ui/icons";


function Layout(props) {
    const useStyles = makeStyles(theme => ({
            float: {
                position: "fixed",
                width: 60,
                height: 60,
                bottom: 40,
                right: 40,
                backgroundColor: "#25d366",
                color: "#FFF",
                borderRadius: 50,
                textAlign: "center",
                fontSize: 30,
                boxShadow: "2px 2px 3px #999",
                zIndex: 100,
            },

            myFloat: {
                marginTop: 12,
            },

        }))
    ;
    const classes = useStyles();
    return (
        <Fragment style={{padding: 0}}>
            <Header style={{marginTop: 0}}/>
            <div>
                <a href="https://api.whatsapp.com/send?phone=971562626178&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202."
                   className={classes.float} target="_blank">
                    <i><WhatsApp className={classes.myFloat} fontSize={"large"}/></i>
                </a>
            </div>
            {props.children}
            <Footer/>
        </Fragment>

    );
}

export default Layout