import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createMuiTheme, MuiThemeProvider, responsiveFontSizes} from "@material-ui/core";

let theme = createMuiTheme({

    html: {
        scrollBehavior: "smooth"
    },
    palette: {

        background: {
            paper: 'rgba(255, 208, 61,0.6)',
            default: '#ffffff'
        },
        primary: {
            main: '#394832',
        },
        secondary: {
            main: '#ea7e24',
        },
    },
    typography: {
        fontFamily: [
            'arial',
        ].join(','),
    },

});

theme = responsiveFontSizes(theme);

const APP = () =>
    <MuiThemeProvider theme={theme}>
        <App/>
    </MuiThemeProvider>

ReactDOM.render(<APP/>, document.getElementById('root'));

