import React from "react";
import MainPage from "../components/MainPages"
import TermAndCondition from "../components/term_condition"
import Samples from "../components/samples"
import {useRoutes} from "hookrouter";
import Layout from "../components/layout"


const routes = {
    "/": () => <MainPage/>,
    "/TermAndConditions": () => <TermAndCondition/>,
    "/Samples": () => <Samples/>,
};


function Route() {
    const routeResult = useRoutes(routes);
    return (
        <Layout>
            {routeResult}
        </Layout>
    )
}

export default Route;
