import React, {Fragment} from 'react';
import MainView from "./MainView";
import AboutUs from "./AboutUs"
import Parallax1 from "./Parallax1";
import Reviews from "./Reviews";
import Services from "./Services";
import Disclaimer from "./Disclaimer";
import Space from "./Space";

function MainPages() {
    return (
        <Fragment>
            <MainView/>
            <AboutUs/>
            <Space/>
            <Services/>
            <Space/>
            <Reviews/>
            <Space/>
            <Parallax1/>
            <Space/>
            <Disclaimer/>
            <Space/>
        </Fragment>
    );
}

export default MainPages