import React from 'react';
import {Grid, makeStyles, Typography} from "@material-ui/core";
import {Assessment, CenterFocusStrong, Settings, Spellcheck} from "@material-ui/icons";

function Services() {
    const useStyles = makeStyles(theme => ({
            card: {
                padding: 30,
                textAlign: "center",
                background: "#fff",
                borderRadius: "0.6em",
                margin: "3em",
                overflow: "hidden",
                cursor: "pointer",
                boxShadow: "0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03)",
                transition: "all ease 200ms",
                '&:hover': {
                    transform: "scale(1.03)",
                    boxShadow: "0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02)",
                },
                '&:img': {
                    width: "100%",
                    objectFit: "cover",
                },
            },
        }))
    ;
    const classes = useStyles();

    return (
        <Grid id={"services"} container justify={"center"}>
            <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                <div style={{padding: 0}}>
                    <Typography
                        style={{color: "#4f1b1d", textAlign: "center", padding: 0, fontWeight: "bold"}}
                    >
                        WHAT WE DO
                    </Typography>
                    <Typography style={{textAlign: "center", padding: 0, fontWeight: "bold"}}
                                variant={"h5"}>
                        OUR SERVICES
                    </Typography>
                </div>
            </Grid>
            <Grid xl={4} lg={4} md={4} sm={6} xs={12}>
                <div className={classes.card} style={{height: 230}}>
                    <Spellcheck style={{color: "#4f1b1d"}} fontSize={"large"}/>
                    <Typography variant={"h6"} style={{fontWeight: "bold", color: "#4f1b1d"}}>Proofreading, Editing,
                        etc.</Typography>
                    <p>Get an expert’s help on your work!</p>
                </div>
            </Grid>
            <Grid xl={4} lg={4} md={4} sm={6} xs={12}>
                <div className={classes.card} style={{height: 230}}>
                    <Assessment style={{color: "#4f1b1d"}} fontSize={"large"}/>
                    <Typography variant={"h6"} style={{fontWeight: "bold", color: "#4f1b1d"}}>Plagiarism & Grammar
                        Reports</Typography>
                    <p>We strive our hardest to ensure that our clients receive authentic work. Get a plagiarism &
                        grammar report on your order for free!</p>
                </div>
            </Grid>
            <Grid xl={4} lg={4} md={4} sm={6} xs={12}>
                <div className={classes.card} style={{height: 230}}>
                    <CenterFocusStrong style={{color: "#4f1b1d"}} fontSize={"large"}/>
                    <Typography variant={"h6"} style={{fontWeight: "bold", color: "#4f1b1d"}}>Customer
                        Focus</Typography>
                    <p>Alif Writers offers 24/7 customer support. Feel free to contact us for any queries!</p>
                </div>
            </Grid>
        </Grid>
    );

}

export default Services